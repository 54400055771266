import { View } from "@novorender/api";
import { ObjectDB } from "@novorender/data-js-api";
import { useCallback } from "react";

import { useAppDispatch } from "app/redux-store-interactions";
import { featuresConfig } from "config/features";
import { GroupStatus } from "contexts/objectGroups";
import { useOpenWidget } from "hooks/useOpenWidget";
import { explorerActions } from "slices/explorer";
import { getAssetUrl } from "utils/misc";

import { useLazyGetPropertiesQuery } from "../api";
import { propertyTreeActions } from "../slice";
import { getPropertyTreeColor } from "../utils";

export function useSetupVersionComparisonPropertyTree() {
    const openWidget = useOpenWidget();
    const dispatch = useAppDispatch();
    const [getProperties] = useLazyGetPropertiesQuery();

    return useCallback(
        async (db: ObjectDB, view: View) => {
            dispatch(explorerActions.enableWidgets([featuresConfig.propertyTree.key]));
            openWidget(featuresConfig.propertyTree.key);
            try {
                const properties = await getProperties(
                    { assetUrl: getAssetUrl(view, "").toString(), path: "Compare" },
                    true,
                ).unwrap();
                if ("values" in properties) {
                    dispatch(propertyTreeActions.expand("Compare"));
                    await selectVersionComparisonProperties(db, properties.values, dispatch);
                }
            } catch (e) {
                console.error("Failed to fetch properties for version comparison", e);
            }
        },
        [dispatch, openWidget, getProperties],
    );
}

async function selectVersionComparisonProperties(
    db: ObjectDB,
    properties: string[],
    dispatch: ReturnType<typeof useAppDispatch>,
) {
    const groups = await Promise.all(
        properties.map(async (propValue, index) => {
            const resp = db.search(
                { searchPattern: [{ property: "Compare", value: propValue, exact: true }] },
                undefined,
            );
            const ids: number[] = [];
            for await (const obj of resp) {
                ids.push(obj.id);
            }
            if (ids.length === 0) {
                return;
            }
            return {
                group: {
                    propertyValue: propValue,
                    ids: Object.fromEntries(ids.map((id) => [id, id])),
                    color: getPropertyTreeColor(index),
                    status: GroupStatus.Selected,
                },
                property: "Compare",
                value: propValue,
            };
        }),
    );

    for (const group of groups) {
        if (group) {
            dispatch(propertyTreeActions.upsertGroup(group));
        }
    }
}
